
import { User } from '@/store/user/types';
import { Vue, Component, VModel } from 'vue-property-decorator';
import { ROOT_ACTIONS } from '@/store/actions';
import { USER_GETTERS } from '@/store/user/getters';
import { addDoc, collection, getDocs, query, where } from 'firebase/firestore';
import { firestore } from '@/firebase';

@Component
export default class CreateEventDialog extends Vue {
  @VModel({ required: true }) show!: boolean;

  participants: User[] = [];

  selected: null | User = null;

  loading = false;

  get user(): User {
    return this.$store.getters[USER_GETTERS.ACTING_USER]
  }

  async getUsers() {
    const users = await getDocs(query(collection(firestore, 'users')))
    return users.docs.map(e => {
      return {
        ...e.data(),
        id: e.id,
        fullName: e.data().firstname + ' ' + e.data().lastname
      } as User;
    })
  }

  async mounted() {
    let users: User[] = await this.getUsers();

    if (this.user.type !== 'ADMIN') {
      const programs = await getDocs(query(collection(firestore, 'programs'), where('coach', '==', this.user.id)))
      const myParticipants = programs.docs.map(e => e.data().participant)
      users = users.filter(e => myParticipants.includes(e.id))
    }

    this.participants = users;
  }

  resetFields() {
    this.selected = null;
  }

  async submit() {
    try {
      this.loading = true;

      if (!this.selected) return;

      let coach = '';

      if (this.user.type === 'ADMIN') {
        const programsOfUser = await getDocs(query(collection(firestore, 'programs'), where('participant', '==', this.selected.id)))
        if (!programsOfUser.empty) {
          coach = programsOfUser.docs[0].data().coach as string;
        } else {
          coach = this.user.id;
        }
      } else {
        coach = this.user.id;
      }

      // check if user is already registered
      const querySnapshot = await getDocs(query(collection(firestore, 'event-attendees'), where('webinarId', '==', this.$route.params.id), where('user', '==', this.selected.id)))
      if (!querySnapshot.empty) {
        this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
          color: 'error',
          text: this.$t('participantAlreadyRegistered')
        })
        return;
      }

      await addDoc(collection(firestore, 'event-attendees'), {
        webinarId: this.$route.params.id,
        user: this.selected.id,
        coach,
        present: false,
        addedActivity: false,
      })

      this.$emit('refresh')
      this.resetFields();
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: 'success',
        text: this.$t('participantAlreadyRegistered')
      })
      this.show = false;
    } catch (error) {
      console.error(error)
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: 'error',
        text: 'Error'
      })
    } finally {
      this.loading = false;
    }
  }

}
